<template>
  <div class="login"></div>
</template>

<script>
import { setToken } from "@/assets/js/auth";
export default {
  data() {
    return {
    };
  },
  beforeCreate:function(){
    if(!!this.$route.query.userid && typeof(this.$route.query.userid) != 'undefined' ){
      this.$post('/weblogin', {
        "userid": this.$route.query.userid
      }).then((m) => {
        if(m.content.type == 'nobody') {
          this.$message({
            type: 'error',
            message: '用户不存在'
          });
          this.$router.push({path: "/Login"}); //登录
        }
        let result = JSON.parse(m.content.content);
        sessionStorage.clear();
        setToken(result.access_token);
        if (m.content.type == "G") {
          this.$router.push({path: "/Administrator"}); //管理员
          sessionStorage.setItem("roleType", 'G');
        } else if (m.content.type == "P") {
          this.$router.push({path: "/Judge"}); //评委
        } else if (m.content.type == "T") {
          this.$router.push({path: "/School"}); //学校
        } else if (m.content.type == "K") {
          this.$router.push({path: "/Administrator"}); //管理员
          sessionStorage.setItem("roleType", 'K');
        }
      }, (err) => {
      }).catch(err => {
      })
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
